import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {NgBusyModule} from 'ng-busy';
import {ngfModule} from 'angular-file';
import {FormsModule} from '@angular/forms';

import {ProductsService} from './services/products.service';
import {ProductsMainComponent} from './components/main/main.component';
import {ProductDetailComponent} from './components/detail/detail.component';
import {PackageHandlerComponent} from './components/package-handler/package-handler.component';
import {ServicesStatusComponent} from './components/services-status/services-status.component';
import {LogsComponent} from './components/logs/logs.component';
import {PackageExecutionDialog} from './dialogs/package-execution/package-execution.dialog';
import {ProductUpdateDialog} from './dialogs/product-update/product-update.dialog';
import {CertificateDialog} from './dialogs/certificate/certificate.dialog';
import {ProductsManageDialog} from './dialogs/products-manage/products-manage.dialog';
import {ProductFormDialog} from './dialogs/product-form/product-form.dialog';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgBusyModule,
        RouterModule,
        ngfModule,
        TranslateModule.forChild(),
        FtCoreModule,
        MaterialModule
    ],
    declarations: [
        LogsComponent,
        ProductsMainComponent,
        ProductDetailComponent,
        PackageHandlerComponent,
        ServicesStatusComponent,

        CertificateDialog,
        ProductUpdateDialog,
        PackageExecutionDialog,
        ProductsManageDialog,
        ProductFormDialog,
    ],
    providers: [
        ProductsService
    ]
})
export class FtmProductsModule {
}

export const lang = {
    title: 'Produits',

    installed_versions: 'installées',
    previous_versions: 'précédentes',

    handle_product: 'Produit: "{{name}}"',
    serve_ip: 'IP de produit',
    serve_dns: 'Dns du produit',

    no_log_has_been_found: 'Aucun fichier journal n\'a été trouvé !!',

    package: {
        actions: 'Actions',
        version: 'Version',
        added_at: 'Ajouter le',
        packaged_at: 'Package du',
        is_installed: 'Version Installé',
        is_installable: 'Installable',
        require_licence: 'Nécessite une licence',
        required_versions_rep: 'Versions requises',
        comment: 'Commentaire',
        execute_install: 'Installer',
        update_licence_key: 'Modifier la clé de licence',
        remove_package: 'Voulez vous supprimé le package "{{type}} - {{version}}"',

        upload_file: 'Upload fichier',
        choose_existing_file: 'Choisir un fichier dans le serveur',
        file_path: 'Chemin du fichier (absolue)',
        file_not_existence: 'Fichier n\'existe pas',

        show_adv: 'Mode avancé',
        close_dialog: 'Terminer',

        installation_succeed: 'Instalation términer avec succès'
    },

    setups: {
        handle_setups: 'Gerer les produits',
        product_name: 'Nom du produit',
        add_product: 'Ajouter produit (DEBUG ONLY)'
    },

    product: {
        licence_key: 'Clé de licence',
        licence_key_invalid: 'Clé fournie non valide',
        licence_machine_uuid_invalid: 'La clé fourni n\'est pas n\'est pas destinée à cette machine',
        licence_product_invalid: 'Le produit du clé fourni n\'est pas n\'est pas destinée à ce produit',
        licence_package_version_invalid: 'La version de clé fournie n\'est pas destinée à cette version de package'
    },

    service: {
        id: 'ServiceFile',
        group_user: 'Group:User',
        load_state: 'LoadState',
        active_state: 'ActiveState',
        sub_state: 'SubState',
        exec_main_pid: 'PID',
        fragment_path: 'Path',
        unit_file_state: 'UnitFileState',

        stop: 'Arrêter',
        enable: 'Activer',
        disable: 'Désactiver',
        start_restart: 'Démarrer / Redémarrage'
    },

    certificate: {
        ip_list: 'Liste des IP',
        dns_list: 'Liste des DNS',
        ip_item: 'IP: {{index}}',
        dns_item: 'DNS: {{index}}',
        dialog_title: 'Certificat SSL',
        common_name: 'Common Name',
        add_ip: 'IP',
        add_dns: 'DNS',
        download_keystore: 'Télécharger keystore',
        lets_encrypt: 'Let\'s Encrypt'
    },

    backup: {
        label: 'Backups',
        backups: 'Copies N° {{index}}',
        cron: 'Cron config N° {{index}}',
        destination: 'Destination N° {{index}}',
        add_backup: 'Ajouter backup',
        main_args: 'Arguments du script principaux'
    }
};

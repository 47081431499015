import {Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, merge, Observable, of, Subscription, timer} from 'rxjs';
import {MEDIUM_DIALOG, pushOrUpdate, TableColumnDefinition} from '@ft/core';
import {ActivatedRoute} from '@angular/router';
import {assign, without} from 'lodash';
import {debounce, filter, map, tap} from 'rxjs/operators';
import {ProductsService} from '../../services/products.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PackageExecutionDialog} from '../../dialogs/package-execution/package-execution.dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ftm-package-handler',
    templateUrl: './package-handler.component.html',
    styleUrls: [
        './package-handler.component.scss'
    ]
})
export class PackageHandlerComponent implements OnInit, OnDestroy {
    public validDrag: any;
    public canInstall = false;
    public source$: Observable<any[]> = of([]);
    public columns: TableColumnDefinition[] = [
        {
            label: 'products.package.version',
            key: 'version',
            type: 'html',

            callBack: element => {
                return `
    <span style="display: flex;">
        <span style="flex: 1 0 auto;${element.is_installed || element.is_installable ? 'font-weight: bold;' : ''}
${element.is_installable ? 'color: var(--ft-success-color);' : ''}">
            ${element.version}
        </span>
        <span class="mat-button-wrapper" style="flex: none">
            ${element.is_installed ?
                    `<md-icon title="${this._translateService.instant('products.package.is_installed')}" 
                            class="mat-primary mat-icon mdi-marker-check mdi"></md-icon>` : ''}
            ${!element.is_installed && element.is_installable ?
                    `<md-icon title="${this._translateService.instant('products.package.is_installable')}" 
                            class="mat-primary mat-icon mdi-star-four-points-circle mdi"></md-icon>` : ''}
        </span>
    </span>`;
            }
        },
        {label: 'products.package.added_at', key: 'created_at', type: 'datetime'},
        {label: 'products.package.packaged_at', key: 'packaged_at', type: 'datetime'},
        /*{label: 'products.package.is_installed', key: 'is_installed', type: 'boolean'},
        {label: 'products.package.is_installable', key: 'is_installable', type: 'boolean'},
        {label: 'products.package.require_licence', key: 'require_licence', type: 'boolean'},*/
        {
            label: 'products.package.required_versions_rep',
            key: 'str_required_versions',
            type: 'html',
            tooltip: 'str_required_versions',
            callBack: element => {
                return `<span title="${element.str_required_versions}" class="text-overflow: ellipsis">${element.str_required_versions}</span>`;
            }
        },
        {label: 'products.package.comment', key: 'comment', type: 'text'},
        {
            label: '', key: 'actions', type: 'actions', actions: [
                {
                    class: 'mat-primary',
                    icon: 'mdi-download',
                    tooltip: 'products.package.execute_install',

                    method: item => this._startExecution(item, false),
                    isDisabled: item => !item.is_installable || item.is_installed || !this.canInstall
                },
                {
                    icon: 'mdi-restart',
                    class: 'mat-primary',
                    tooltip: 'products.package.update_licence_key',
                    method: item => this._startExecution(item, true),
                    isDisabled: item => !item.is_installed || !item.require_licence || !this.canInstall
                },
                {
                    class: 'mat-warn',
                    icon: 'mdi-delete',
                    method: item => this._handleDelete(item),
                    isDisabled: item => item.is_installed || !this.canInstall
                }
            ]
        }

    ];

    private _items: any[] = [];
    private _currentContext: any[];
    private _wsSubscription: Subscription;
    private _routerSubscription: Subscription;

    constructor(
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _route: ActivatedRoute,
        private _service: ProductsService,
        private _translateService: TranslateService
    ) {
    }

    public ngOnInit() {
        this._routerSubscription = merge(this._route.queryParams, this._service.currentProduct$)
            .pipe(
                debounce(() => timer(1)),
                filter(() => !!this._service.currentProduct$.getValue()),
                map(() => [
                    this._service.currentProduct$.getValue().id,
                    (this._route.queryParams as any).value.type
                ])
            )
            .subscribe(data => this._handleContextChanges(data));

        this._wsSubscription = this._service.executionNotifySubscription()
            .subscribe((data: boolean) => this.canInstall = data);
    }

    public ngOnDestroy() {
        this._wsSubscription.unsubscribe();
        this._routerSubscription.unsubscribe();
    }

    public handlePackage(packageFile) {
        this._service.uploadPackage(this._currentContext[0], this._currentContext[1], packageFile)
            .subscribe({
                next: () => this.source$ = this._updateSource(),
                error: error => this._handleUploadError(error)
            });
    }

    public attachFromPath() {
        this.source$ = this._service.attachFromFs(this._currentContext[0], this._currentContext[1])
            .pipe(
                tap((response) => {
                    const {error} = response;

                    if (error) {
                        this._handleUploadError(response);
                    }

                    this.source$ = this._updateSource();
                }),
                map(() => [])
            );
    }

    private _handleContextChanges(context) {
        this._currentContext = context;
        this.source$ = this._updateSource();
    }

    private _updateSource() {
        return forkJoin([
            this._service.canExecute(),
            this._service.getProductPackages(this._currentContext[0], this._currentContext[1])
        ]).pipe(
            tap(data => this.canInstall = data[0] as boolean),
            map(data => data[1] as any[]),
            tap(data => this._items = data)
        );
    }

    private _handleUploadError(response) {
        if (response.status === 422) this._snackBar.open(response.error.msg, null, {duration: 5000});
    }

    private _handleDelete(item) {
        this.source$ = this._service.deletePackage(item)
            .pipe(
                map(() => without(this._items, item)),
                tap(items => this._items = items)
            );
    }

    private _startExecution(_package, updateOnly) {
        const dialogRef = this._dialog.open(PackageExecutionDialog, assign(MEDIUM_DIALOG, {
            width: '875px',
            disableClose: true,
            data: {package: _package, updateOnly}
        }));

        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this._items = pushOrUpdate(this._items, data);
                    this.source$ = of(this._items);
                }
            }
        );
    }
}

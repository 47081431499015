import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {AppConfigsService, GeneralPurposeService, ToolbarConfig} from '@ft/core';
import {chain} from 'lodash';
import {saveAs} from '@d0whc3r/file-saver';
import {MatSnackBar} from '@angular/material/snack-bar';
import {map, mergeMap} from 'rxjs/operators';
import {SwUpdate} from '@angular/service-worker';

@Component({
    selector: 'ftm-root-app',
    template: `
        <router-outlet></router-outlet>
    `,
    styles: []
})
export class AppComponent implements OnInit {
    constructor(
        private _swUpdate: SwUpdate,
        private _generalPurpose: GeneralPurposeService
    ) {
    }

    public ngOnInit(): void {
        if (this._swUpdate.isEnabled) {
            this._swUpdate.available.subscribe(() => {
                this._generalPurpose.showAppCodeUpdateToast();
            });
        }
    }
}

// main component
@Component({
    selector: 'ftm-main',
    host: {class: 'ft-main-component'},
    template: `
        <ft-app-toolbar [config]="toolbarConfig"></ft-app-toolbar>
        <router-outlet></router-outlet>
    `
})
export class FtmMainComponent {
    public toolbarConfig: ToolbarConfig;

    constructor(
        private _snackBar: MatSnackBar,
        private _translate: TranslateService,
        private _appConfigs: AppConfigsService,
        private _generalPurpose: GeneralPurposeService
    ) {
        this.toolbarConfig = {
            logo: '/assets/logo.png',
            logoutIcon: 'mdi-logout',
            links: [
                {
                    icon: 'mdi-apps',
                    label: 'products.title',
                    path: '/products'
                },
                {
                    icon: 'mdi-cogs',
                    label: 'shared_services.title',
                    path: '/shared-services'
                },
                {
                    icon: 'mdi-file-find',
                    label: 'file_manager.title',
                    path: '/file-manager/main'
                },
                {
                    icon: 'mdi-database-eye',
                    label: 'db.title',
                    path: '/db-managers/nosql-client'
                },
                {
                    icon: 'mdi-console',
                    label: 'ssh.title',
                    path: '/ssh',
                    target: '_blank'
                },
                {
                    external: true,
                    icon: 'mdi-printer-settings',
                    label: 'shared.cups_title',
                    path: '/cups/'
                }
            ],
            actions: [
                {
                    class: '',
                    icon: 'mdi-download',
                    tooltip: 'products.certificate.download_keystore',
                    method: () => this.downloadKeystore()
                },
                {
                    class: '',
                    icon: 'mdi-progress-clock',
                    tooltip: 'shared.restart.planed_restart',
                    method: () => this.autoRestart()
                },
                {
                    class: '',
                    href: '/printers',
                    icon: 'mdi-printer-pos-cog',
                    tooltip: 'printing.tooltip_label'
                },
                {
                    class: 'mat-primary',
                    icon: 'mdi-information-outline',
                    tooltip: _appConfigs.machineUuid,
                    method: () => this.copyMachineCode()
                },

                {
                    class: 'ft-success-color button-separated',
                    icon: 'mdi-restart-alert',
                    tooltip: 'shared.restart.reboot_device',
                    method: () => this.handleDevice('reboot')
                },
                {
                    class: 'mat-warn',
                    icon: 'mdi-power-plug-off',
                    tooltip: 'shared.restart.shutdown_device',
                    method: () => this.handleDevice('shutdown')
                }
            ],
            phoneNumbers: []
        };
    }

    public downloadKeystore() {
        this._generalPurpose.downloadNative('/keystore/')
            .subscribe(response => {
                const contentDispositionHeader = response.headers.get('content-disposition');
                const fileName = chain(contentDispositionHeader)
                    .split(';').last()
                    .split('=').last()
                    .value();

                saveAs(response.body, fileName);
            });
    }

    public autoRestart() {
        this._generalPurpose.getByEvent('various.restart_config')
            .pipe(
                mergeMap(data => this._generalPurpose.openPromptDialog(
                    'shared.restart.title', 'shared.restart.cron_config',
                    null, null, data.CRON
                )),
                map(config => new Object({CRON: config})),
                mergeMap(data => this._generalPurpose.getByEvent('various.set_restart', data))
            )
            .subscribe(() => {
                const copyMsg = this._translate.instant('shared.restart.config_success');
                this._snackBar.open(copyMsg, null, {duration: 1000});
            });
    }

    public copyMachineCode() {
        navigator.clipboard.writeText(this._appConfigs.machineUuid)
            .then(() => {
                const copyMsg = this._translate.instant('shared.machine_code_copy_success');
                this._snackBar.open(copyMsg, null, {duration: 1000});
            });
    }

    public handleDevice(cmd) {
        this._generalPurpose.openConfirmDialog(`shared.restart.${cmd}_confirm`)
            .pipe(
                mergeMap(() => this._generalPurpose.getByEvent(`various.${cmd}`))
            ).subscribe();
    }
}

import {chain, get} from 'lodash';

const PRODUCT_EXTRA: any[] = [
    {path: 'services-status', icon: 'mdi-powershell', params: {}, label: 'SERVICES'},
    {path: 'logs', icon: 'mdi-math-log', params: {}, label: 'LOGS'},
];

interface ProductBackups {
    cron: string;
    backups: number;
    destination: string;
}

export class ProductModel {
    public id: number;
    public name: string;
    // tslint:disable-next-line:variable-name
    public serve_ip: string;
    // tslint:disable-next-line:variable-name
    public serve_dns: string;

    public logs: string[];
    public services: string[];
    // tslint:disable-next-line:variable-name
    public is_manager: boolean;
    // tslint:disable-next-line:variable-name
    public package_types: string[];

    // tslint:disable-next-line:variable-name
    public has_backup: boolean;
    public backups: ProductBackups[];
    // tslint:disable-next-line:variable-name
    public backups_main_args: string;

    // tslint:disable-next-line:variable-name
    public previous_versions: object;
    // tslint:disable-next-line:variable-name
    public installed_versions: object;

    constructor(data) {
        this.id = get(data, 'id');
        this.name = get(data, 'name');
        this.serve_ip = get(data, 'serve_ip');
        this.serve_dns = get(data, 'serve_dns');

        this.logs = get(data, 'handled_logs', []);

        this.services = get(data, 'services', []);
        this.is_manager = get(data, 'is_manager', false);
        this.package_types = get(data, 'package_types');

        this.backups = get(data, 'backups');
        this.has_backup = get(data, 'has_backup');
        this.backups_main_args = get(data, 'backups_main_args');

        this.previous_versions = get(data, 'previous_versions', {});
        this.installed_versions = get(data, 'installed_versions', {});
    }

    get path() {
        return `/products/${this.id}/detail`;
    }

    get defaultLink() {
        return this.links[0];
    }

    get links() {
        return chain(this.package_types).map(
            type => new Object({
                label: type,
                params: {type},
                path: 'package-handler',
                icon: 'mdi-package-variant-closed',
            })
        ).concat(PRODUCT_EXTRA).value();
    }

    get hasLogs() {
        return this.logs.length > 0;
    }
}

<form #licenceForm="ngForm" novalidate fxLayout="column"
      (submit)="updateOnly ? updateLicenceKey() : startInstallation()"
      *ngIf="package.require_licence && !submitted" class="ft-busy-container">
    <div [ngBusy]="loader"></div>
    <div mat-dialog-content fxLayout="column">
        <mat-form-field appearance="outline" class="with-error-label">
            <mat-label>{{'products.product.licence_key' | translate}}</mat-label>
            <textarea matInput [placeholder]="'products.product.licence_key' | translate" rows="5"
                      [(ngModel)]="licenceKey" name="licenceKey" required #licenceKeyModel="ngModel"
                      [ftCustomValidation]="validateField()" [noWatch]="true" [watchedValue]="licenceKey">
            </textarea>
            <mat-error
                    *ngIf="licenceKeyModel.errors?.inValidLicenceKey">{{'products.product.licence_key_invalid' | translate}}</mat-error>
            <mat-error
                    *ngIf="licenceKeyModel.errors?.inValidProduct">{{'products.product.licence_product_invalid' | translate}}</mat-error>
            <mat-error
                    *ngIf="licenceKeyModel.errors?.inValidMachineUuid">{{'products.product.licence_machine_uuid_invalid' | translate}}</mat-error>
            <mat-error
                    *ngIf="licenceKeyModel.errors?.inValidPackageVersion">{{'products.product.licence_package_version_invalid' | translate}}</mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-stroked-button color="primary" [disabled]="licenceForm.invalid" type="submit" class="has-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            <span translate="common.validate"></span>
        </button>
        <button mat-stroked-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1">
            <span translate="common.cancel"></span>
        </button>
    </div>
</form>

<div fxLayout="column" *ngIf="!package.require_licence || submitted">
    <div mat-dialog-content fxLayout="column" [ngClass]="{'show-adv-mode': showAdvMode}">
        <div fxFlex="nogrow" fxLayout="column">
            <h4 class="progress-title" *ngIf="message.hasInstance" translate="products.package.installation_succeed"></h4>
            <h4 class="progress-title" [ngClass]="{'has-error': message.hasError}"
                *ngIf="message.label">{{message.label}}</h4>
            <mat-progress-bar *ngIf="!message.hasError && !message.hasInstance" [@heightAnimation]
                              [mode]="progressMode" [value]="message.progress">
            </mat-progress-bar>
            <span class="progress-step" *ngIf="!message.hasError && !message.hasInstance" [@heightAnimation]>
                {{message.step ? message.step : 'X'}}/{{message.total}}
            </span>
        </div>
        <div fxFlex="1 0 auto" fxLayout="column">
            <div fxFlex="0 1 auto" fxLayout="row" class="adv-mode-btn">
                <span fxFlex="grow"></span>
                <button fxFlex="0 0 auto" mat-button color="primary" (click)="toggleAdvMode()">
                    <mat-icon fontSet="mdi" fontIcon="mdi-arrow-expand"></mat-icon>
                    <span translate="products.package.show_adv"></span>
                </button>
            </div>
            <div fxFlex="0 0 340px" [ngClass]="{hidden: !showAdvMode}" class="adv-mode-container mat-elevation-z2">
                <p class="content">
                    <span class="message" *ngFor="let message of messages" fxLayout="column"
                          [ngClass]="{'has-error': message.hasError}">
                        <span fxFlex="nogrow" fxLayout="row">
                            <span fxFlex="nogrow" class="message-step">{{message.step ? message.step : 'X'}}
                                / {{message.total}}</span>
                            <span fxFlex></span>
                            <span fxFlex="nogrow" class="message-progress">[{{message.progress}} / 100%]</span>
                        </span>
                        <span fxFlex="nogrow" class="message-label">
                            {{message.label}}
                        </span>
                        <span *ngIf="message.hasOutput" class="message-output">{{message.output}}</span>
                        <span *ngIf="message.hasError" class="message-error">{{message.error}}</span>
                        <mat-divider></mat-divider>
                    </span>

                </p>
            </div>
        </div>

    </div>
    <div mat-dialog-actions *ngIf="message.hasError || message.hasInstance" [@heightAnimation]>
        <button mat-flat-button color="warn" type="button"
                [mat-dialog-close]="null" tabindex="-1" *ngIf="message.hasError">
            <span translate="products.package.close_dialog"></span>
        </button>
        <button mat-flat-button color="primary" type="button" [mat-dialog-close]="message.instance" tabindex="-1"
                *ngIf="message.hasInstance">
            <span translate="products.package.close_dialog"></span>
        </button>
    </div>
</div>